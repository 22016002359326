import { AnimatePresence } from "framer-motion";
import ReactDOM from 'react-dom/client';

import {
  BrowserRouter, Route,
  Routes
} from "react-router-dom";

import Transitions from "utils/transition";

//New Imports
import { CardTitle } from "components/card/card_title/card_title.js";
import { CardTitlePasserCommande } from "components/card/card_title/passer_commande/passer_commande";
import { CardHolder } from "components/card_holder/card_holder.js";
import { Carousel } from "components/carousel/carousel.js";
import { Footer } from 'components/footer/footer.js';
import 'components/general/background.css';
import 'components/general/clean.css';
import 'components/general/colors.css';
import 'components/general/positions.css';
import 'components/general/sizes.css';
import { Map } from "components/map/map";
import { NavigationBarHeader } from 'components/navbar/navbar_pizza.js';
import Pin from "images/pin.svg";
import Leaflet from "leaflet";
import { Loading } from 'pages/loading/loading.js';
import { Nouveautes } from "pages/nouveautes/nouveautes";
import { PizzasDesserts } from "pages/pizzas desserts/pizzas_desserts";
import { Pizzas } from 'pages/pizzas/pizzas.js';
import { Marker } from 'react-leaflet';
import { useFetch } from 'utils/communication';
import { filterMainPagePizzas } from "utils/filters/filterMainPagePizzas";
import { filterPizzaNouveaute } from "utils/filters/filterPizzaNouveaute";
import './index.css';

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <AnimatePresence exitBeforeEnter>
    <BrowserRouter>
      <Routes>
        <Route key="1" path="/" element={<Index />} />
        <Route key="2" path="pizzas" element={<Pizzas />} />
        <Route key="3" path="pizzas_desserts" element={<PizzasDesserts />} />
        <Route key="4" path="nouveautes" element={<Nouveautes />} />
      </Routes>
    </BrowserRouter>
  </AnimatePresence>
);

function Index() {

  [window.loadingEtablissement, window.errorEtablissement, window.dataEtablissement] = useFetch("api/etablissement", window.dataEtablissement);
  [window.loadingOuverture, window.errorOuverture, window.dataOuverture] = useFetch("api/ouvertures", window.dataOuverture);
  [window.loadingPizzaBasique, window.errorPizzaBasique, window.dataPizzaBasique] = useFetch("api/pizza-basiques?populate=*", window.dataPizzaBasique);
  [window.loadingPizzaClassique, window.errorPizzaClassique, window.dataPizzaClassique] = useFetch("api/pizza-classiques?populate=*", window.dataPizzaClassique);
  [window.loadingPizzaGastronomique, window.errorPizzaGastronomique, window.dataPizzaGastronomique] = useFetch("api/pizza-gastronomiques?populate=*", window.dataPizzaGastronomique);
  [window.loadingPizzaDessert, window.errorPizzaDessert, window.dataPizzaDessert] = useFetch("api/pizza-desserts?populate=*", window.dataPizzaDessert);
  [window.loadingParameters, window.errorParameters, window.dataParameters] = useFetch("api/parametre", window.dataParameters);

  if (window.errorEtablissement ||
    window.errorOuverture ||
    window.errorPizzaBasique ||
    window.errorPizzaClassique ||
    window.errorPizzaGastronomique ||
    window.errorParameters ||
    window.errorPizzaDessert) {
    return <p>Error.</p>;
  }

  if (window.loadingEtablissement ||
    window.loadingOuverture ||
    window.loadingPizzaBasique ||
    window.loadingPizzaClassique ||
    window.loadingPizzaGastronomique ||
    window.loadingParameters ||
    window.loadingPizzaDessert) {
    return <Loading />;
  }

  let pizzasBasique = window.dataPizzaBasique.data;
  let PizzaClassique = window.dataPizzaClassique.data;
  let pizzasGastronomique = window.dataPizzaGastronomique.data;
  let pizzasDessert = window.dataPizzaDessert.data;

  let etablissement = window.dataEtablissement.data
  let parameters = window.dataParameters.data;
  let openingTime = window.dataOuverture.data


  let allPizzas = [];
  allPizzas = allPizzas.concat(pizzasBasique);
  allPizzas = allPizzas.concat(PizzaClassique);
  allPizzas = allPizzas.concat(pizzasGastronomique);
  allPizzas = allPizzas.concat(pizzasDessert);

  let pizzasMainPage = filterMainPagePizzas(allPizzas);
  let pizzasNouveaute = filterPizzaNouveaute(allPizzas);

  let pizzaMomentUrl;
  if(pizzasNouveaute.legnth !== 0){
    pizzaMomentUrl = (pizzasNouveaute[getRandomInt(pizzasNouveaute.length -1)])?.attributes.Image.data.attributes.url;
  }

  let phone = etablissement.attributes.Telephone;
  let adress = etablissement.attributes.Adresse;
  let deliveryRadius = etablissement.attributes.Rayon_De_Livraison_En_Km;
  let latInput = etablissement.attributes.Latitude;
  let lngInput = etablissement.attributes.Longitude;
  let interval = parameters.attributes.Temps_Pour_Changer_Photo_Pizza_Accueil_En_Secondes * 1000;
  let transitionTime = parameters.attributes.Temps_Pour_Realiser_Transition_Accueil_En_Secondes * 1000;

  let center = {
    lat: latInput,
    lng: lngInput
  };

  let zoomInput = 15;

  let pinIcon = Leaflet.icon({
    iconUrl: Pin,
    iconRetinaUrl: Pin,
    iconAnchor: [5, 55],
    popupAnchor: [10, -44],
    iconSize: [50, 50],
  });

  return <>
    <Transitions>
      <NavigationBarHeader />
      <Carousel urlImages={pizzasMainPage} timeToChangeMs={interval} transitionTimeMs={transitionTime} />
      <CardHolder>
        <CardTitle title="Où sommes nous">
          <Map zoom={zoomInput} center={center}>
            <Marker
              position={[latInput, lngInput]}
              icon={pinIcon}
            >
            </Marker>
          </Map>
        </CardTitle>
        <CardTitlePasserCommande pizzas={pizzasMainPage} openingTime={openingTime} etablissement={etablissement} showOpening={true} imageContact={pizzaMomentUrl} />
      </CardHolder>
      <Footer adress={adress} contact={phone} />
    </Transitions>
  </>
}

function getRandomInt(max) {
  return Math.floor(Math.random() * max);
}
