import { CardImagePizza } from "components/card/card_image/card_image_pizza/card_image_pizza";
import { CardHolder } from "components/card_holder/card_holder";
import { DropDownMenu } from "components/dropdown_menu/dropdown_menu";
import { Footer } from "components/footer/footer";
import { LabelButtons } from "components/label/label_buttons/label_buttons";
import { LabelHolder } from "components/label_holder/label_holder";
import { NavigationBarHeader } from "components/navbar/navbar_pizza";
import { Loading } from "pages/loading/loading";
import { useState } from "react";
import { baseURL, useFetch } from "utils/communication.js";
import { filterPizzasCreme } from "utils/filters/filterPizzasCreme";
import { filterPizzasTomate } from "utils/filters/filterPizzasTomate";
import Transitions from "utils/transition";
import "./pizzas.css";

import { CardTitleText } from "components/card/card_title_text/card_title_text";
import { CardWideImageTilteText } from "components/card/card_wide_image_title_text/card_wide_image_title_text";

export function Pizzas() {

    const [isCremeActive, onCremeUpdate] = useState(false);
    const [isTomateActive, onTomateUpdate] = useState(false);
    const [isBasiqueActive, onBasiqueUpdate] = useState(false);
    const [isClassiqueActive, onClassiqueUpdate] = useState(false);
    const [isGastronomiqueActive, onGastronomiqueUpdate] = useState(false);
   
    [window.loadingPersonalisation, window.errorPersonalisation, window.dataPersonalisation] = useFetch("api/personalisation?populate=*", window.dataPersonalisation);
    [window.loadingPizzaBasique, window.errorPizzaBasique, window.dataPizzaBasique] = useFetch("api/pizza-basiques?populate=*", window.dataPizzaBasique);
    [window.loadingPizzaClassique, window.errorPizzaClassique, window.dataPizzaClassique] = useFetch("api/pizza-classiques?populate=*", window.dataPizzaClassique);
    [window.loadingPizzaGastronomique, window.errorPizzaGastronomique, window.dataPizzaGastronomique] = useFetch("api/pizza-gastronomiques?populate=*", window.dataPizzaGastronomique);
    [window.loadingSupplements, window.errorSupplements, window.dataSupplements] = useFetch("api/suplements", window.dataSupplements);

    [window.loadingEtablissement, window.errorEtablissement, window.dataEtablissement] = useFetch("api/etablissement", window.dataEtablissement);

    if (window.errorEtablissement ||
        window.errorPizzaBasique ||
        window.errorPizzaClassique ||
        window.errorPizzaGastronomique ||
        window.errorSupplements ||
        window.errorPersonalisation) {
        return <p>Error.</p>;
    }

    if (window.loadingEtablissement ||
        window.loadingPizzaBasique ||
        window.loadingPizzaClassique ||
        window.loadingPizzaGastronomique ||
        window.loadingSupplements||
        window.loadingPersonalisation) {
        return <Loading />;
    }

    let etablissement = window.dataEtablissement.data;

    let personalisation = window.dataPersonalisation.data;

    let pizzasBasique = window.dataPizzaBasique.data;
    let PizzasClassique = window.dataPizzaClassique.data;
    let pizzasGastronomique = window.dataPizzaGastronomique.data;

    let supplements = window.dataSupplements.data;

    let phone = etablissement.attributes.Telephone;
    let adress = etablissement.attributes.Adresse;

    let pizzasBasiqueFiltered = [];
    let pizzasClassiqueFiltered = [];
    let pizzasGastronomiqueFiltered = [];

    if (isCremeActive) {
        pizzasBasiqueFiltered = filterPizzasCreme(pizzasBasique);
        pizzasClassiqueFiltered = filterPizzasCreme(PizzasClassique);
        pizzasGastronomiqueFiltered = filterPizzasCreme(pizzasGastronomique);
    }
    if (isTomateActive) {
        pizzasBasiqueFiltered = filterPizzasTomate(pizzasBasique);
        pizzasClassiqueFiltered = filterPizzasTomate(PizzasClassique);
        pizzasGastronomiqueFiltered = filterPizzasTomate(pizzasGastronomique);
    }

    if (pizzasBasiqueFiltered.length === 0) {
        pizzasBasiqueFiltered = pizzasBasique;
    }
    if (pizzasClassiqueFiltered.length === 0) {
        pizzasClassiqueFiltered = PizzasClassique;
    }
    if (pizzasGastronomiqueFiltered.length === 0) {
        pizzasGastronomiqueFiltered = pizzasGastronomique;
    }

    function onClickCreme() {
        onCremeUpdate(!isCremeActive);
        onTomateUpdate(false);
    }

    function onClickTomate() {
        onTomateUpdate(!isTomateActive);
        onCremeUpdate(false);
    }

    function onClickBasique() {
        onBasiqueUpdate(!isBasiqueActive);
    }

    function onClickClassique() {
        onClassiqueUpdate(!isClassiqueActive);
    }

    function onClickGastronomique() {
        onGastronomiqueUpdate(!isGastronomiqueActive);
    }

    return (
        <>
            <Transitions className="transition">
                <NavigationBarHeader />
                <LabelHolder>
                    <LabelButtons >
                        <button id="creme_button" onClick={onClickCreme} className={(isCremeActive) ? "active" : "inactive"}>Crème</button>
                        <button id="tomate_button" onClick={onClickTomate} className={(isTomateActive) ? "active" : "inactive"}>Tomate</button>
                    </LabelButtons>
                    <p>*Les bases crème ou tomate sont des suggestions il est possible de les changer à votre convenance</p>
                </LabelHolder>
                
                <CardHolder>
                    <DropDownMenu title="Pizzas Basiques" callback={onClickBasique}>
                        <CardHolder className={(isBasiqueActive) ? "dropdown_active" : "dropdown_inactive"}>
                            {
                                pizzasBasiqueFiltered.map((value) => {
                                    return (
                                        <CardImagePizza data={value}></CardImagePizza>
                                    );
                                })
                            }
                        </CardHolder>
                    </DropDownMenu>
                    <DropDownMenu title="Pizzas Classiques" callback={onClickClassique}>
                        <CardHolder className={(isClassiqueActive) ? "dropdown_active" : "dropdown_inactive"}>
                            {
                                pizzasClassiqueFiltered.map((value) => {
                                    return (
                                        <CardImagePizza data={value}></CardImagePizza>
                                    );
                                })
                            }
                        </CardHolder>
                    </DropDownMenu>
                    <DropDownMenu title="Pizzas Gourmandes" callback={onClickGastronomique}>
                        <CardHolder className={(isGastronomiqueActive) ? "dropdown_active" : "dropdown_inactive"}>
                            {
                                pizzasGastronomiqueFiltered.map((value) => {
                                    return (
                                        <CardImagePizza data={value}></CardImagePizza>
                                    );
                                })
                            }
                        </CardHolder>
                    </DropDownMenu>
                </CardHolder>

                <CardHolder>
                    <CardWideImageTilteText title={personalisation.attributes.Titre} description={personalisation.attributes.Description} src={baseURL + personalisation.attributes.Image.data.attributes.url}>

                    </CardWideImageTilteText>
                    <CardTitleText title="Supplément">
                        {
                            supplements.map((value) => {
                                return (<>
                                    <div key={value.id} className="content">
                                        <p>{value.attributes.Nom}</p>
                                        <p className="price">{value.attributes.Prix + " €"}</p>
                                    </div>
                                </>);
                            })
                        }

                    </CardTitleText>

                </CardHolder>

                <Footer adress={adress} contact={phone} />
            </Transitions>
        </>);
}