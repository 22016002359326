import { CardImagePizza } from "components/card/card_image/card_image_pizza/card_image_pizza";
import { CardHolder } from "components/card_holder/card_holder";
import { Footer } from "components/footer/footer";
import { NavigationBarHeader } from "components/navbar/navbar_pizza";
import { Loading } from "pages/loading/loading";
import { useFetch } from "utils/communication.js";
import Transitions from "utils/transition";

export function PizzasDesserts() {

    [window.loadingPizzaDessert, window.errorPizzaDessert, window.dataPizzaDessert] = useFetch("api/pizza-desserts?populate=*", window.dataPizzaDessert);
    [window.loadingEtablissement, window.errorEtablissement, window.dataEtablissement] = useFetch("api/etablissement", window.dataEtablissement);

    if (window.errorEtablissement || window.errorPizzaDessert) {
        return <p>Error.</p>;
    }

    if (window.loadingEtablissement || window.loadingPizzaDessert) {
        return <Loading />;
    }

    let etablissement = window.dataEtablissement.data;
    let pizzas = window.dataPizzaDessert.data;

    let phone = etablissement.attributes.Telephone;
    let adress = etablissement.attributes.Adresse;

    return (
        <>
            <Transitions className="transition">
                <NavigationBarHeader />
                <CardHolder>
                    {
                        pizzas.map((value) => {
                            return (
                                <CardImagePizza data={value}></CardImagePizza>
                            );
                        })
                    }

                </CardHolder>
                <Footer adress={adress} contact={phone} />
            </Transitions>
        </>);
}