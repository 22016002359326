import { useState } from 'react';
import { MdOutlineExpandLess, MdOutlineExpandMore } from 'react-icons/md';
import "./dropdown_menu.css";

export function DropDownMenu(props) {
    const [isExpanded, updateState] = useState(false);
    function onClick() {
        props.callback()
        updateState(!isExpanded);
    }

    return <div className="dropdown_menu">
        <div className="h1_holder">
            <h1>{props.title}</h1>
        </div>
        {props.children}
        <button onClick={onClick}>
            {
                (!isExpanded) ? <MdOutlineExpandMore /> : <MdOutlineExpandLess />
            }
        </button>
    </div>
}