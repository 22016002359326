import { baseURL } from "utils/communication";
import { CardImage } from "../card_image.js";

export function CardImagePizza(props) {
    let value = props.data;

    return <CardImage key={value.id} title={value.attributes.Nom} src={baseURL + value.attributes.Image.data.attributes.url}>
        <p>{value.attributes.Ingredients}</p>
        <div className="content_footer">
            {
                value.attributes.Prix_Petite &&
                <>
                    <div className="content">
                        {
                            "Petite : " + value.attributes.Prix_Petite + " €"
                        }
                    </div>
                    <div className="content">
                        {"Grande : " + value.attributes.Prix_Grande + " €"}
                    </div>
                </>
            }
            {
                <div className="content">
                    {"Prix : " + value.attributes.Prix_Grande + " €"}
                </div>
            }
        </div>
    </CardImage>
}