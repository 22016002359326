import { ReactComponent as Logo } from "images/logo.svg";
import { Link } from "react-router-dom";
import "./navbar_pizza.css";

export function NavigationBarHeader(props) {
    return (<header>
        <Link to="/"><Logo /></Link>
        <nav>
            <Link to="/pizzas" className="pizzas nav_element">Pizzas</Link>
            <Link to="/pizzas_desserts" className="pizzas nav_element">Pizzas Desserts</Link>
            <Link to="/nouveautes" className="pizzas nav_element">Nouveautés</Link>
        </nav>
    </header>);
}